export const getAuthtoken = () => localStorage.getItem("alleypin:token")
export const setAuthtoken = token =>
  localStorage.setItem("alleypin:token", token)

export const getVendor = token => {
  try {
    const strings = token.split(".")
    const tokenInfo = JSON.parse(
      decodeURIComponent(
        window.atob(strings[1].replace(/-/g, "+").replace(/_/g, "/"))
      )
    )
    return tokenInfo.data._vendor
  } catch (e) {
    alert(`token parse error: ${e}`)
  }
}
